import React from 'react';
import * as styles from './faq.module.css';

import Layout from '../components/Layout/Layout';
import Banner from '../components/Banner';
import Container from '../components/Container';

const FaqPage = (props) => {
  return (
    <Layout>
      <div className={styles.root}>
        <Banner
          maxWidth={'650px'}
          name={`Frequently Asked Questions`}
          bgImage={'/faqCover.png'}
          color={'var(--standard-white)'}
          height={'350px'}
        />
        <Container>
        <div className={styles.section}>
  <span>Lorem Ipsum</span>
  <div className={styles.subSection}>
    <h3>Lorem Ipsum</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
      elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
      commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
      dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
    </p>
    <p>
      Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
      posuere cubilia Curae; Integer et nulla vel libero tincidunt dapibus
      eget id sem. Proin non urna in justo tincidunt placerat. Vestibulum
      nec elit ut nulla cursus ultricies vel id ligula.
    </p>
    <p>
      Duis nec vestibulum risus. Quisque quis suscipit libero. Morbi tempor
      sapien id fermentum mattis. Proin sit amet nisl luctus, tempus nisl
      nec, finibus velit.
    </p>
  </div>
  <div className={styles.subSection}>
    <h3>Lorem Ipsum</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
      elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
      commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
      dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
    </p>
    <p>
      Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
      posuere cubilia Curae; Integer et nulla vel libero tincidunt dapibus
      eget id sem. Proin non urna in justo tincidunt placerat. Vestibulum
      nec elit ut nulla cursus ultricies vel id ligula.
    </p>
  </div>
</div>

<div className={styles.section}>
  <span>Lorem Ipsum</span>
  <div className={styles.subSection}>
    <h3>Lorem Ipsum</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
      elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
      commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
      dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
    </p>
    <p>
      Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
      posuere cubilia Curae; Integer et nulla vel libero tincidunt dapibus
      eget id sem. Proin non urna in justo tincidunt placerat. Vestibulum
      nec elit ut nulla cursus ultricies vel id ligula.
    </p>
    <p>
      Duis nec vestibulum risus. Quisque quis suscipit libero. Morbi tempor
      sapien id fermentum mattis. Proin sit amet nisl luctus, tempus nisl
      nec, finibus velit.
    </p>
  </div>
  <div className={styles.subSection}>
    <h3>Lorem Ipsum</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
      elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
      commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
      dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
    </p>
    <p>
      Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
      posuere cubilia Curae; Integer et nulla vel libero tincidunt dapibus
      eget id sem. Proin non urna in justo tincidunt placerat. Vestibulum
      nec elit ut nulla cursus ultricies vel id ligula.
    </p>
    <p>
      Duis nec vestibulum risus. Quisque quis suscipit libero. Morbi tempor
      sapien id fermentum mattis. Proin sit amet nisl luctus, tempus nisl
      nec, finibus velit.
    </p>
  </div>
  <div className={styles.subSection}>
    <h3>Lorem Ipsum</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dictum
      elit vel sapien ullamcorper, ac euismod mi scelerisque. Phasellus
      commodo magna eget nunc egestas, non auctor felis volutpat. Nullam
      dapibus lectus eget tortor bibendum, eu hendrerit libero finibus.
    </p>
    <p>
      Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
      posuere cubilia Curae; Integer et nulla vel libero tincidunt dapibus
      eget id sem. Proin non urna in justo tincidunt placerat. Vestibulum
      nec elit ut nulla cursus ultricies vel id ligula.
    </p>
  </div>
</div>

        </Container>
      </div>
    </Layout>
  );
};

export default FaqPage;
